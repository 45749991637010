import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Us" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image" style={{"display":"none"}} >
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  Welcome to S. Damji & Sons , your number one source for all spice needs. We're
                  dedicated to giving you the very best of spices, with a focus on deliverability,
                  quality and cooperation.
                </p>
                <p className="about-wrapper__info-text">
                  Founded in 1970 by Damji Narshi Soni, S. Damji & Sons has come a long way from its
                  beginnings in Bombay now to APMC Vashi. When Damji Soni first started out, his
                  drive for quality drove them to start this company so that we at S. Damji & Sons
                  can offer you our services. We now serve customers all over the world, and are
                  thrilled to manoeuvre our passion with you.
                </p>
                <p className="about-wrapper__info-text">
                  We hope you enjoy our products as much as we enjoy offering them to you. If you
                  have any questions or comments, please don't hesitate to contact us.
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Location
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
